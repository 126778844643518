import React from 'react';
import './Balancer.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import StartupTax from './StartupTax';
import PartialTax from './PartialTax';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './NavigationBar.css';

class NavigationBar extends React.Component {
    render() {
        return (
            <div >
                <Router >
                    <Navbar className="navbar" bg="light" variant="light" >

                        <Navbar.Brand href="/Home">
                            <img
                                src="/logo.jpg"
                                width="30"
                                height="30"
                            />
                            SmoothWork
                        </Navbar.Brand>
                        <Nav>
                            <NavDropdown title="Tax Balancers">
                                <NavDropdown.Item href="/PartialTax">Partial Tax Scheme</NavDropdown.Item>
                                <NavDropdown.Item href="/StartupTax">Startup Tax Scheme</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <NavDropdown title="Tax Schemes">
                                <NavDropdown.Item target="_blank" href="https://www.iras.gov.sg/irashome/Quick-Links/Tax-Rates/Individual-Income-Tax-Rates/">Income Tax</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" href="https://www.iras.gov.sg/irashome/Businesses/Companies/Learning-the-basics-of-Corporate-Income-Tax/Corporate-Tax-Rates--Corporate-Income-Tax-Rebates-and-Tax-Exemption-Schemes/">Startup and Partial Tax Scheme</NavDropdown.Item>

                            </NavDropdown>
                        </Nav>
                    </Navbar>

                    <Switch>
                        <Route path="/PartialTax">
                            <PartialTax />
                        </Route>
                        <Route path="/StartupTax">
                            <StartupTax />
                        </Route>
                    </Switch>
                </Router>
            </div>
        )
    }
}

export default NavigationBar;