

import React, { useState } from "react";
import { createMuiTheme, responsiveFontSizes, MuiThemeProvider, Container, TextField, Fab, Card, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh'
import Clear from "@material-ui/icons/Clear";
import { v4 as uuidv4 } from 'uuid';


function StartupTax() {

    // Floating Action Bar CSS
    const addDirectorFabStyle = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    };

    const resetFabStyle = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 100,
        left: 'auto',
        position: 'fixed',
    };

    const closeCardFabStyle = {
        right: 10,
        top: 15,
        color: '#4F0809',
        background: '#F08080'
    }


    // Cards CSS
    const resultCardStyle = {
        position: 'fixed',
        left: 30,
        bottom: 20,
        maxWidth: '30vw',
        background: '#3AAFB9',
        padding: '20px',
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.9)",
        background: '#fde9e9',
    }

    const instructionsCardStyle = {
        position: 'fixed',
        left: 30,
        bottom: 200,
        maxWidth: '20vw',
        backgroundColor: '#eeeeee',
        padding: '20px',
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.9)",
        fontSize: '12px',
    }

    const directorCardStyle = {
        display: 'flex',
        flexDirection: 'row',
        margin: '0px',
        padding: '20px',
        background: '#EFE6DD',
        margin: 'auto',
        width: '60%',
    }

    const companyCardStyle = {
        display: 'flex',
        flexDirection: 'row',
        width: '60%',
        margin: '20px auto 20px auto',
        background: '#E6E6E6',
        padding: '20px'
    }

    const introCardStyle = {
        padding: '1vw',
        width: '60%',
        textAlign: 'center',
        margin: '20px auto 20px auto',
        background: '#ffffff',
        boxShadow: "0 0 0 0",
    }

    // Container CSS
    const rightColumnContainerStyle = {
        // display: 'flex',
        flexDirection: 'col',
        width: '15vw',
        alignItems: 'center',
        margin: '0',
        padding: '0',
    }

    const leftColumnContainerStyle = {
        margin: '0',
        width: '15vw'
    }

    const overallContainerStyle = {
        marginBottom: '150px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '70%',
    }

    const mainContainerStyle = {
        borderRadius: 16
    }


    // LOGIC
    const [globalInfo, setGlobalInfo] = useState([
        {
            numberOfDirectors: 1,
            totalSalaryDeductions: 0,
            totalTaxRate: 0,
            averageTaxRate: 0,
            companyTaxRate: 0,
            difference: 0.00,
        }
    ])

    const [company, setCompany] = useState([
        {
            revenue: 0,
            taxableIncome: 0,
            profits: 0,
            taxRate: 0,
        }
    ]);

    const [directors, setDirectors] = useState([
        {
            id: uuidv4(),
            salary: 0,
            taxableIncome: 0,
            taxRate: 0,
        }
    ]);
    const handleDirectorChange = (id, event) => {
        const updatedDirectors = directors.map(i => {
            if (id === i.id) {
                var finalTaxRate = globalInfo.at(0).totalTaxRate
                var prevSalary = Number(i['salary'])
                finalTaxRate -= Number(i['taxRate'])
                var taxRate = 0

                if (event.target.value === 0 || event.target.value === null || isNaN(event.target.value)) {
                    i['salary'] = 0
                    i['taxableIncome'] = 0
                    i['taxRate'] = 0
                } else {
                    i['salary'] = event.target.value
                    var taxableIncome = calculateIncomeTax(event.target.value).toFixed(2)
                    taxRate = calculateIncomeTaxRate(event.target.value, taxableIncome).toFixed(2)
                    if (isNaN(taxRate)) {
                        taxRate = 0;
                    }
                    i['taxableIncome'] = taxableIncome
                    i['taxRate'] = taxRate
                }

                finalTaxRate += Number(i['taxRate'])


                const updatedGlobalInfo = globalInfo.map(j => {
                    j['totalSalaryDeductions'] += Number(i['salary']) - prevSalary
                    j['totalTaxRate'] = finalTaxRate
                    var average = (finalTaxRate / j['numberOfDirectors']).toFixed(2)
                    j['averageTaxRate'] = average
                    j['difference'] = (average - j['companyTaxRate']).toFixed(2)

                    return j
                })

                setGlobalInfo(updatedGlobalInfo)

                handleCompanyChange(null)
            }

            return i
        })

        setDirectors(updatedDirectors)
    }

    // event refers to revenue of company
    const handleCompanyChange = event => {
        var revenue = 0
        if (event === null) {
            revenue = company.at(0).revenue
        } else {
            revenue = event.target.value
        }
        const updatedCompany = company.map(i => {
            const profits = revenue - globalInfo.at(0).totalSalaryDeductions
            var taxRate = 0

            if (revenue === 0 || revenue === null || isNaN(revenue)) {
                i['revenue'] = 0
                i['profits'] = 0
                i['taxRate'] = 0
                i['taxableIncome'] = 0

            } else {
                i['revenue'] = revenue
                i['profits'] = profits
                var taxableIncome = calculateCompanyTax(profits).toFixed(2)
                taxRate = calculateIncomeTaxRate(profits, taxableIncome).toFixed(2)
                if (isNaN(taxRate)) {
                    taxRate = 0;
                }
                i['taxableIncome'] = taxableIncome
                i['taxRate'] = taxRate
            }

            const updatedGlobalInfo = globalInfo.map(j => {
                j['companyTaxRate'] = taxRate
                j['difference'] = (j['averageTaxRate'] - taxRate).toFixed(2)

                return j
            })

            setGlobalInfo(updatedGlobalInfo)

            return i
        })

        setCompany(updatedCompany)
    }

    const handleAddDirector = () => {
        setDirectors([...directors, {
            id: uuidv4(),
            salary: 0,
            taxableIncome: 0,
            taxRate: 0
        }])

        const updatedGlobalInfo = globalInfo.map(j => {
            j['numberOfDirectors'] += 1
            j['averageTaxRate'] = (j['totalTaxRate'] / j['numberOfDirectors']).toFixed(2)
            j['difference'] = (j['averageTaxRate'] - j['companyTaxRate']).toFixed(2)
            return j
        })
        setGlobalInfo(updatedGlobalInfo)
    }

    const handleDeleteDirector = id => {
        const list = [...directors]
        var director = list.find(d => d.id === id)

        const updatedGlobalInfo = globalInfo.map(j => {
            j['numberOfDirectors'] -= 1
            j['totalTaxRate'] -= director.taxRate
            j['averageTaxRate'] = (j['totalTaxRate'] / j['numberOfDirectors']).toFixed(2)
            j['difference'] = (j['averageTaxRate'] - j['companyTaxRate']).toFixed(2)
            return j
        })
        setGlobalInfo(updatedGlobalInfo)

        list.splice(list.findIndex(d => d.id === id), 1)
        setDirectors(list);
    }

    const handleResetValues = () => {
        setDirectors([{
            id: uuidv4(),
            salary: 0,
            taxableIncome: 0,
            taxRate: 0
        }])

        setCompany([{
            revenue: 0,
            taxableIncome: 0,
            profits: 0,
            taxRate: 0,
        }])

        setGlobalInfo([{
            numberOfDirectors: 1,
            totalSalaryDeductions: 0,
            totalTaxRate: 0,
            averageTaxRate: 0,
            companyTaxRate: 0,
            difference: 0.00,
        }])
    }

    return (
        <Container style={overallContainerStyle}>
            <Card style={introCardStyle}>
                <Typography variant="h4">
                    The roboCFO Startup Tax Balancer
                    <p />
                </Typography>
                Experience the roboTaxBalancer – optimize taxes between your company and yourself/directors by balancing between your personal tax rate and company tax rates. Achieve equalized tax rates to optimse your personal taxes.
                <p />
            </Card>
            <Fab
                style={resetFabStyle}
                size='large'
                color='secondary'
                variant='extended'
                onClick={handleResetValues}
            >
                <RefreshIcon />
                Reset all values.
            </Fab>
            <Fab
                style={addDirectorFabStyle}
                size='large'
                color='primary'
                variant='extended'
                onClick={handleAddDirector}
            >
                <AddIcon />
                Add Director
            </Fab>
            {directors.map((director, index) => (
                <Container
                    style={mainContainerStyle}
                    onChange={event => handleDirectorChange(director.id, event)}
                >
                    <Card style={directorCardStyle}>
                        <Container style={leftColumnContainerStyle}>
                            <Typography variant='h6' style={{ fontWeight: 600 }}>
                                Director {index + 1} Information
                            </Typography>
                            <TextField
                                name="salary"
                                label="Director's Fees"
                                variant="filled"
                                type="number"
                                value={director.salary}
                            />
                        </Container>
                        <Container style={rightColumnContainerStyle}>
                            <TextField
                                name="tax"
                                label="Taxable Income"
                                variant="filled"
                                value={director.taxableIncome}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                            <TextField
                                name="taxRate"
                                label="Tax Rate (%)"
                                variant="filled"
                                value={director.taxRate}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Container>


                        <Fab
                            style={closeCardFabStyle}
                            size='small'
                            disabled={directors.length === 1}
                            onClick={() => handleDeleteDirector(director.id)}>
                            <Clear />
                        </Fab>

                    </Card>
                </Container>
            ))
            }

            {/* COMPANY */}
            <Container
                style={mainContainerStyle}
                onChange={event => handleCompanyChange(event)}
            >
                <Card style={companyCardStyle}>
                    <Container style={leftColumnContainerStyle}>
                        <Typography variant='h6' style={{ fontWeight: 600 }}>
                            Company Information
                        </Typography>
                        <TextField
                            name="companyProfits"
                            label="Company Profits"
                            variant="filled"
                            type="number"
                            value={company.at(0).revenue}
                        />
                    </Container>
                    <Container style={rightColumnContainerStyle}>
                        <TextField
                            name="CAP"
                            label="Company Adjusted Profits"
                            variant="filled"
                            value={company.at(0).profits}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <TextField
                            name="tax"
                            label="Taxable Income"
                            variant="filled"
                            value={company.at(0).taxableIncome}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <TextField
                            name="taxRate"
                            label="Tax Rate (%)"
                            variant="filled"
                            value={company.at(0).taxRate}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Container>
                </Card>
            </Container>

            <Card style={instructionsCardStyle}>
                <Typography variant='h5' style={{ fontWeight: 600 }}>
                    Instructions <br />
                </Typography>
                <Typography variant='p'>
                <li>Add your director's fees you would like to draw from your company in the 'Director Information' tab. The roboCFO automatically calculates your personal income tax payable.</li>
                <li>Next, input your Company Profits before drawing of director's fees. The roboCFO will then calculate the 'Company Adjusted Profits' after director's fees, thereby calculating your company's tax rates.</li>
                <li>Refer to the 'Results' card on the bottom left corner. It automatically calculates the tax difference between your's and your company's tax payable.</li>
                <li>Try to achieve minimal tax differential, thereby optimising your taxes.</li>
                </Typography>
            </Card>

            <Card style={resultCardStyle}>
                <Typography variant='h5' style={{ fontWeight: 600 }}>
                    Results <br />
                </Typography>
                <Typography variant='h6'>
                    Current Tax Difference: {globalInfo.at(0).difference}% <br />
                    {globalInfo.at(0).difference < 0 ? 'Company has higher tax rates' : globalInfo.at(0).difference > 0 ? 'Company has lower tax rates' : 'Equal tax rates'}
                </Typography>
            </Card>
        </Container >
    )
}

function calculateIncomeTaxRate(income, tax) {
    if (income === 0 || tax === 0) {
        return 0;
    } else {
        return ((tax / income) * 100);
    }
}

function calculateCompanyTax(income) {
    let remainingIncome = 0;
    if (income < 0) {
        return 0;

    } else if (income <= 100000) {
        return income * 0.0425

    } else if (income <= 200000) {
        remainingIncome = income - 100000;
        return remainingIncome * 0.085 + 4250;

    } else if (income > 200000) {
        remainingIncome = income - 200000;
        return remainingIncome * 0.17 + 12750;

    } else {
        return -5;
    }
}

function calculateIncomeTax(income) {
    let remainingIncome = 0;
    if (income <= 20000 || income === Infinity) {
        return 0;
    } else if (income <= 30000) {
        remainingIncome = income - 20000;
        return remainingIncome * 0.02;

    } else if (income <= 40000) {
        remainingIncome = income - 30000;
        return remainingIncome * 0.04 + 200;

    } else if (income <= 80000) {
        remainingIncome = income - 40000
        return remainingIncome * 0.07 + 550;

    } else if (income <= 120000) {
        remainingIncome = income - 80000
        return remainingIncome * 0.12 + 3350;;

    } else if (income <= 160000) {
        remainingIncome = income - 120000
        return remainingIncome * 0.15 + 7950;

    } else if (income <= 200000) {
        remainingIncome = income - 160000
        return remainingIncome * 0.18 + 13950;

    } else if (income <= 240000) {
        remainingIncome = income - 200000
        return remainingIncome * 0.19 + 21150;

    } else if (income <= 280000) {
        remainingIncome = income - 240000
        return remainingIncome * 0.195 + 28750;

    } else if (income <= 320000) {
        remainingIncome = income - 280000
        return remainingIncome * 0.2 + 36550;

    } else {
        remainingIncome = income - 320000
        return remainingIncome * 0.22 + 44550;
    }
}

export default StartupTax